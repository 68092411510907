import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Header = ({ color }) => {
  const [isNavbarActive, setIsNavbarActive] = useState(false);
  const [isHeaderActive, setIsHeaderActive] = useState(false);
  const [isGoTopVisible, setIsGoTopVisible] = useState(false);

  // Toggle navbar and overlay
  const toggleNavbar = () => {
    setIsNavbarActive((prevState) => !prevState);
  };

  // Handle scroll event for header and go-top button
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 400) {
        setIsHeaderActive(true);
        setIsGoTopVisible(true);
      } else {
        setIsHeaderActive(false);
        setIsGoTopVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Handle keydown event
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "a" || event.key === "s") {
        // Handle key 'a' and 's' actions if needed
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => document.removeEventListener("keydown", handleKeyDown);
  }, []);

  return (
    <>
      <header
        className={`header ${isHeaderActive ? "active" : ""}`}
        data-header
      >
        <div className="container">
          <div
            className={`overlay ${isNavbarActive ? "active" : ""}`}
            data-overlay
          ></div>

          <Link to="/">
            <h1 className="logo" style={{ color: `${color}` }}>
              3 Dolts
            </h1>
          </Link>

          <nav
            className={`navbar ${isNavbarActive ? "active" : ""}`}
            data-navbar
          >
            <div className="navbar-top">
              <Link to="/" className="logo" style={{ color: `${color}` }}>
                3 Dolts
              </Link>

              <button
                className="nav-close-btn"
                aria-label="Close Menu"
                onClick={toggleNavbar}
                data-nav-close-btn
              >
                <ion-icon name="close-outline"></ion-icon>
              </button>
            </div>

            <ul className="navbar-list">
              <li className="navbar-item">
                <Link
                  to="/"
                  className="navbar-link"
                  data-navbar-link
                  style={{ color: `${color}` }}
                >
                  Home
                </Link>
              </li>

              <li className="navbar-item">
                <Link
                  to="/about"
                  className="navbar-link"
                  data-navbar-link
                  style={{ color: `${color}` }}
                >
                  About
                </Link>
              </li>

              <li className="navbar-item">
                <Link
                  to="/services"
                  className="navbar-link"
                  data-navbar-link
                  style={{ color: `${color}` }}
                >
                  Services
                </Link>
              </li>

              <li className="navbar-item">
                <Link
                  to="/features"
                  className="navbar-link"
                  data-navbar-link
                  style={{ color: `${color}` }}
                >
                  Features
                </Link>
              </li>
              <li className="navbar-item">
                <Link
                  to="/projects"
                  className="navbar-link"
                  data-navbar-link
                  style={{ color: `${color}` }}
                >
                  Projects
                </Link>
              </li>
              <li className="navbar-item">
                <Link
                  to="/pricing"
                  className="navbar-link"
                  data-navbar-link
                  style={{ color: `${color}` }}
                >
                  Pricing
                </Link>
              </li>
            </ul>
          </nav>

          <Link to="/pricing" className="btn">
            <ion-icon
              name="chevron-forward-outline"
              aria-hidden="true"
            ></ion-icon>

            <span>Get A Quote</span>
          </Link>

          <button
            className="nav-open-btn"
            aria-label="Open Menu"
            onClick={toggleNavbar}
            style={{ color: `${color}` }}
            data-nav-open-btn
          >
            <ion-icon name="menu-outline"></ion-icon>
          </button>
        </div>
      </header>
      {/* <!-- 
     - #GO TO TOP
   --> */}

      {isGoTopVisible && (
        <a
          href="#top"
          className="go-top active"
          aria-label="Go To Top"
          data-go-top
        >
          <ion-icon name="arrow-up-outline"></ion-icon>
        </a>
      )}

      {/* <!-- 
     - #WHATSAAP
   --> */}

      <a
        href="https://wa.me/+254712012113"
        target="_blank"
        class="whatsaap active"
        rel="noreferrer"
      >
        <ion-icon name="logo-whatsapp"></ion-icon>
      </a>
    </>
  );
};

export default Header;
