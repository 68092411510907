import React from "react";
// import hero from "../../images/hero-banner.png";
import hero from "../../images/home.svg";
import { FaCopy } from "react-icons/fa";
import toast from "react-hot-toast";

const Hero = () => {
  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Copied to clipboard!");
      })
      .catch((err) => {
        toast.error("Failed to copy!");
      });
  };

  const callUs = () => {
    const message = "0712012113";
    toast(<ToastWithCopy message={message} />, { duration: 10000 });
    window.location.href = "tel:0712012113";
  };

  const ToastWithCopy = ({ message }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span style={{ display: "flex" }}>
        Our Number:{" "}
        <span style={{ color: "green", marginLeft: "4px" }}>{message}</span>
      </span>
      <FaCopy
        style={{ marginLeft: "8px", cursor: "pointer", color: "green" }}
        onClick={() => copyToClipboard(message)}
      />
    </div>
  );
  return (
    <>
      {" "}
      <section class="hero" id="home">
        <div class="container">
          <div class="hero-content">
            <p class="hero-subtitle">We Are Website Developers From Kenya</p>

            <h2 class="h2 hero-title">We Design Interfaces That Users Love</h2>

            <p class="hero-text">
              We pour our hearts into every project, working tirelessly to
              deliver exceptional web solutions tailored to elevate your
              business. From immersive WebApps to captivating websites, we're
              here to turn your vision into reality.
            </p>

            <button onClick={() => callUs()} class="btn">
              Call Us
            </button>
          </div>

          <figure class="hero-banner">
            <img
              src={hero}
              width="694"
              height="529"
              loading="lazy"
              alt="hero-banner"
              class="w-100 banner-animation"
            />
          </figure>
        </div>
      </section>
    </>
  );
};

export default Hero;
